import React, { useState, useContext } from 'react';
import { MdOutlineLocalPhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { Functionality } from '../../context/Functionality'
import { FaCheckCircle } from "react-icons/fa";

const ContactForm = () => {

  const { inputs, busInputs, handleChange, handleBusChange, handleBusSubmit, setSendClicked, showForm, handleSubmit, setShowForm } = useContext(Functionality);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    date: '',
    referralCode: '',
    message: '',
    subscribe: false,
  });

  const [busFormData, setBusFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    date: '',
    businessCode: '',
    message: ''
  });

  const [busErrors, setBusErrors] = useState({});


  const handleBusValidation = (e) => {
    const { name, value } = e.target;
    setBusFormData({
      ...busFormData,
      [name]: value,
    });
  };


  const [errors, setErrors] = useState({});

  const handleValidation = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleForm = (e) => {
    e.preventDefault();
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = 'Please fill out this field.';
    if (!formData.lastName) formErrors.lastName = 'Please fill out this field.';
    if (!formData.email) formErrors.email = 'Please provide a valid email.';
    if (!formData.message) formErrors.message = 'Please enter your message.';
    if (!formData.phoneNumber) formErrors.phoneNumber = 'Please enter your phone number.';
    if (!formData.date) formErrors.date = 'Please provide a date.';

    setErrors(formErrors);

    // If no errors, handle form submission
    if (Object.keys(formErrors).length === 0) {
      handleSubmit();
    }
  };

  const handleBusForm = (e) => {
    e.preventDefault();
    let busFormErrors = {};

    if (!busFormData.name) busFormErrors.name = 'Please fill out this field.';
    if (!busFormData.email) busFormErrors.email = 'Please provide a valid email.';
    if (!busFormData.message) busFormErrors.message = 'Please enter your message.';
    if (!busFormData.phoneNumber) busFormErrors.phoneNumber = 'Please enter your phone number.';
    if (!busFormData.date) busFormErrors.date = 'Please provide a date.';

    setBusErrors(busFormErrors);

    if (Object.keys(busFormErrors).length === 0) {
      handleBusSubmit(); // Assuming `handleBusSubmit` is your form submission function
    }
  };

  return (
    <section className=" py-2 lg:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className=" block md:grid lg:grid-cols-2 grid-cols-1 flex flex-col-reverse lg:flex-row-reverse">
          <div className="lg:mb-0 mb-10">
            <div className="group w-full h-full">
              <div className="relative h-96 lg:h-auto">
                <img
                  src="/images/image.png"
                  alt="ContactUs tailwind section"
                  className="w-full h-full lg:rounded-l-2xl rounded-l-[10px] bg-blend-multiply bg-indigo-700 object-cover"
                />
                <h1 className="font-roboto text-white font-bold absolute top-11 left-11 text-24 lg:text-50 leading-36 lg:leading-60">
                  Contact us
                </h1>
                <div className="absolute bottom-0 w-full lg:p-11 p-5">
                  <div className="bg-white rounded-lg p-6 block">
                    <a href="javascript:;" className="flex items-center mb-6">
                      <MdOutlineLocalPhone style={{ width: '30px', height: '30px', color: '#4f46e5' }} />
                      <h5 className="text-black text-base font-normal leading-6 ml-5">470-601-1911</h5>
                    </a>
                    <a href="javascript:;" className="flex items-center mb-6">
                      <CiMail style={{ width: '30px', height: '30px', color: '#4f46e5' }} />
                      <h5 className="text-black text-base font-normal leading-6 ml-5">Pagedone1234@gmail.com</h5>
                    </a>
                    <a href="javascript:;" className="flex items-center">
                      <IoLocationOutline style={{ width: '30px', height: '30px', color: '#4f46e5' }} />
                      <h5 className="text-black text-base font-normal leading-6 ml-5">
                        654 Sycamore Avenue, Meadowville, WA 76543
                      </h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" p-5 lg:pt-4 lg:pl-11 lg:pr-11 lg:pb-11 lg:rounded-r-2xl rounded-r-[10px]">
            <>
              <h2 className="text-black text-center pb-2 font-roboto font-semibold text-24 lg:text-50 leading-36 lg:leading-60 mb-2">Reach Out to Us</h2>
              <h4 className="text-indigo-600 text-center pb-2 font-roboto  text-2xl md:text-2xl font-semibold leading-10 ">Are You a</h4>
              <div className=" flex flex-row lg:justify-left justify-center pb-6 gap-y-2 sm:gap-y-0 xl:gap-x-5 gap-x-3">

                <button className="relative w-5/12 md:w-52 lg:w-40 xl:w-52 block md:inline-flex md:mt-3 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group  bg-indigo-600 text-white"
                  onClick={() => setShowForm(true)}
                >
                  <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 rounded-md">
                    BUSINESS
                  </span>
                  {showForm && (
                    <FaCheckCircle className="absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-white" />
                  )}
                </button>

                <span className="inline-block text-indigo-600 mx-2 md:mt-1 self-center">or</span>


                <button className="relative w-5/12 md:w-52 lg:w-40 xl:w-52 block md:inline-flex md:mt-3 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group  bg-indigo-600 text-white"
                  onClick={() => setShowForm(false)}
                >
                  <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75  rounded-md ">
                    STUDENT
                  </span>
                  {!showForm && (
                    <FaCheckCircle className="absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-white" />
                  )}
                </button>
              </div>

            </>
            {showForm &&
              <>
                <form className="w-full" onSubmit={handleBusForm}>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                        Business Name
                      </label>
                      <input
                        className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none ${busErrors.name ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        value={busFormData.name}
                        placeholder="Name of Business*"
                        type="text"
                        name="name"
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      />
                      {busErrors.name && <p className="text-red-500 text-xs italic mt-1">{busErrors.name}</p>}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                        Email
                      </label>
                      <input
                        className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none ${busErrors.email ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        name="email"
                        value={busFormData.email}
                        placeholder="Email*"
                        type="email"
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      />
                      {busErrors.email && <p className="text-red-500 text-xs italic mt-1">{busErrors.email}</p>}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Phone Number
                      </label>
                      <input
                        className={`appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none   ${busErrors.phoneNumber ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        id="phoneNumber"
                        name="phoneNumber"
                        value={busInputs.phoneNumber}
                        placeholder="Phone Number*"
                        type="number"
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      />
                      {busErrors.phoneNumber && (
                        <p className="text-red-500 text-xs italic mt-1">{busErrors.phoneNumber}</p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-date"
                      >
                        Date
                      </label>
                      <input
                        className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none  ${busErrors.date ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        datepicker
                        type={'date'}
                        id="date"
                        name="date"
                        value={busInputs.date}
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      />
                      {busErrors.date && (
                        <p className="text-red-500 text-xs italic mt-1">{busErrors.date}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-date"
                      >
                        Referral Code
                      </label>
                      <input
                        className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none  ${busErrors.businessCode ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        id="referralCode"
                        name="referralCode"
                        placeholder='Referral Code'
                        value={busInputs.referralCode}
                        type="text"
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      />
                      {busErrors.businessCode && (
                        <p className="text-red-500 text-xs italic mt-1">{busErrors.businessCode}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-message"
                      >
                        Your Message
                      </label>
                      <textarea
                        rows="4"
                        className={`appearance-none bg-transparent border-b w-full text-gray-700  pl-0 py-4 leading-tight focus:outline-none  ${busErrors.message ? 'border-red-500' : 'border-indigo-500'
                          }`}
                        id="message"
                        name="message"
                        placeholder="What would you like us to know?"
                        type="textarea"
                        value={busInputs.message}
                        onChange={(e) => {
                          handleBusChange(e);
                          handleBusValidation(e);
                        }}
                      ></textarea>
                      {busErrors.message && (
                        <p className="text-red-500 text-xs italic mt-1">{busErrors.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end w-full px-3 mb-6">
                    <button
                      className="shadow bg-indigo-600 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded flex justify-between"
                      id="contact-button" onClick={() => setSendClicked(true)}
                      type="submit"
                    >
                      <p>Submit</p>
                    </button>
                  </div>
                </form>
              </>
            }
            {!showForm &&
              <form className="w-full" onSubmit={handleForm}>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      First Name
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none  ${errors.firstName ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      type="text"
                      placeholder="Enter first name"
                      name="firstName"
                      value={inputs.firstName}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Last Name
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none   ${errors.lastName ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      type="text"
                      placeholder="Enter last name"
                      name="lastName"
                      value={inputs.lastName}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-email"
                    >
                      Email Address
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none  ${errors.email ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      type="text"
                      placeholder="Enter email address"
                      name="email"
                      value={inputs.email}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.email}</p>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-phone-number"
                    >
                      Phone Number
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none   ${errors.phoneNumber ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      type="text"
                      placeholder="Enter phone number"
                      name="phoneNumber"
                      value={inputs.phoneNumber}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.phoneNumber}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-date"
                    >
                      Date
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none  ${errors.date ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      datepicker
                      type={'date'}
                      id="default-datepicker"
                      placeholder="Select date"
                      name="date"
                      value={inputs.date}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.date && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.date}</p>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-referral-code"
                    >
                      Referral Code
                    </label>
                    <input
                      className={`appearance-none bg-transparent border-b border-indigo-500 w-full text-gray-700 pl-0 py-4 leading-tight focus:outline-none   ${errors.referralCode ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      type="text"
                      placeholder="Enter referral code"
                      name="referralCode"
                      value={inputs.referralCode}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    />
                    {errors.referralCode && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.referralCode}</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-message"
                    >
                      Your Message
                    </label>
                    <textarea
                      rows="4"
                      className={`appearance-none bg-transparent border-b w-full text-gray-700  pl-0 py-4 leading-tight focus:outline-none  ${errors.message ? 'border-red-500' : 'border-indigo-500'
                        }`}
                      placeholder="Your message"
                      name="message"
                      value={inputs.message}
                      onChange={(e) => {
                        handleChange(e);
                        handleValidation(e);
                      }}
                    ></textarea>
                    {errors.message && (
                      <p className="text-red-500 text-xs italic mt-1">{errors.message}</p>
                    )}
                  </div>
                </div>

                <div className="flex justify-between w-full px-3 mb-6">
                  <div className="md:flex md:items-center">
                    <label className="block text-gray-500 font-bold">
                      <input
                        className="mr-2 leading-tight"
                        type="checkbox"
                        name="subscribe"
                        checked={formData.subscribe}
                        onChange={(e) => {
                          handleChange(e);
                          handleValidation(e);
                        }}
                      />
                      <span className="text-sm">Send me your newsletter!</span>
                    </label>
                  </div>
                  <button
                    className="shadow bg-indigo-600 hover:bg-indigo-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-6 rounded"
                    type="submit"
                  >  Submit
                  </button>
                </div>
              </form>
            }
          </div>
        </div>
      </div></section >
  );
};

export default ContactForm;
