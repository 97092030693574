import React from 'react'

export default function SectionThree() {
    return (
        <section className="pt-[20px] pb-[20px] md:pt-[90px] md:pb-[60px] sm:pt-[80px] sm:pb-[50px] "
            style={{
                backgroundImage: "url('https://html.xpressbuddy.com/techco/demo/demo_assets/images/backgrounds/bg_image_2.webp')",
                backgroundSize: 'cover', // This ensures the image covers the entire section
                backgroundPosition: 'center', // Center the image
                backgroundRepeat: 'no-repeat',
            }
            }
        >
            <div className="wraper" >
                <div className="text-center mb-[80px]" >
                    <h2 className="text-24 lg:text-50 leading-36 lg:leading-60 font-roboto font-bold capitalize
                       mt-[15px] relative text-[#fff]">
                        Over 100 Successful Projects Across 15 + Industries  <span className='text-#fff' >Innovative Solutions Tailored to Your Industry</span></h2 >

                    <p className=' text-15 lg:text-20 text-[#fff]  font-roboto font-bold pt-5'>We’ve leveraged our expertise to deliver high-quality projects  <br className='lg:block hidden' /> in a wide array of sectors. Explore the industries we've mastered:</p>
                </div >
                <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-x-4" >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px]" >
                            <div className=" text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] mt-5 leading-[50px] h-[50px]" src="/images/icon/Frame1.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] after:transition-all" >
                                    Social media
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Driving engagement with scalable and feature-rich platforms.</p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame6.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Education
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Enhancing learning experiences through digital transformation.</p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame11.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    AR technology
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Pioneering interactive and immersive solutions.</p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame2.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Fitness & Sports
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Crafting tools that empower fitness and wellness journeys.</p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame7.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Automotive & Transportation
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Innovating mobility solutions for the future of transportation.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame12.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Entertainment & TV Series
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Creating platforms that redefine media consumption.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame3.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Banking & Finance
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Revolutionizing secure and efficient financial services.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame8.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Healthcare & Medicine
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Enhancing patient care with cutting-edge health technologies.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame10.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Startups
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Bringing new ideas to life with scalable and innovative solutions.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame4.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Construction
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Optimizing workflows and project management for the construction industry.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame9.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Restaurants, food delivery
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Boosting operational efficiency and customer experiences in food services.
                            </p>
                        </div >
                    </div >
                    <div className="text-center bg-[#f3f6fe] rounded-md mb-3 hover:bg-[#dce5f7]" >
                        <div className="lg:p-[10px] mb-[30px]" >
                            <div className="text-center
                            flex items-center justify-center mx-auto">
                                <img className="w-[50px] leading-[50px] h-[50px] mt-5" src="/images/icon/Frame14.png"
                                    alt="" />
                            </div >
                            <h2 className=" text-17 font-medium my-[5px]" >
                                <a href="service-single.html"
                                    className="text-[#222222] transition-all" >
                                    Religion & Faith Communities
                                </a >
                            </h2 >
                            <p className='text-13 text-[#222222]  font-roboto font-normal pt-2'>Developing platforms that connect and engage faith-based communities.
                            </p>
                        </div >
                    </div >
                </div >
            </div >
        </section >
    )
}
