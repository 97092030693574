import React, { useState } from 'react'
import { FaFacebook, FaGithub } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";

export default function SectionFive() {
    // State to hold styles for each card
    const [styles, setStyles] = useState({});

    const handleMouseMove = (e, index) => {
        const card = e.currentTarget;
        const cardRect = card.getBoundingClientRect();

        const cardWidth = cardRect.width;
        const cardHeight = cardRect.height;

        const centerX = cardRect.left + cardWidth / 2;
        const centerY = cardRect.top + cardHeight / 2;

        const mouseX = e.clientX - centerX;
        const mouseY = e.clientY - centerY;

        const rotateX = (15 * mouseY) / (cardHeight / 2);  // Max rotation along X-axis
        const rotateY = (-15 * mouseX) / (cardWidth / 2);  // Max rotation along Y-axis

        // Calculate shadow offset
        const shadowX = (10 * mouseX) / (cardWidth / 2);   // Adjust shadow on X-axis
        const shadowY = (10 * mouseY) / (cardHeight / 2);  // Adjust shadow on Y-axis

        // Update the specific card's style with tilt and shadow
        setStyles((prevStyles) => ({
            ...prevStyles,
            [index]: {
                transform: ``,
                transition: 'transform 0.1s ease, box-shadow 0.1s ease',
                boxShadow: `${shadowX}px ${shadowY}px 20px rgba(0, 0, 0, 0.3)`,  // Dynamic shadow
            },
        }));
    };

    const handleMouseLeave = (index) => {
        // Reset the style for the specific card when the mouse leaves
        setStyles((prevStyles) => ({
            ...prevStyles,
            [index]: {
                transform: '',
                transition: 'transform 0.5s ease, box-shadow 0.5s ease',
                boxShadow: 'none',  // Remove shadow on leave
            },
        }));
    };

    const teamMembers = [
        {
            name: 'Bonnie Green',
            position: 'CEO & Web Developer',
            img: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png',
            description: 'Bonnie drives the technical strategy of the flowbite platform and brand.',
        },
        {
            name: 'Jese Leos',
            position: 'CTO',
            img: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png',
            description: 'Jese drives the technical strategy of the flowbite platform and brand.',
        },
        {
            name: 'Michael Gough',
            position: 'Senior Front-end Developer',
            img: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png',
            description: 'Michael drives the technical strategy of the flowbite platform and brand.',
        },
        {
            name: 'Lana Byrd',
            position: 'Marketing & Sales',
            img: 'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/sofia-mcguire.png',
            description: 'Lana drives the technical strategy of the flowbite platform and brand.',
        },
    ];

    return (
        <section className="bg-white dark:bg-gray-900" >
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 " >
                <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16" >
                    <h2 className="block font-roboto text-[#232f4b] text-24 lg:text-50 leading-36 lg:leading-60 font-bold text-center pb-5 pt-5" > Our Team</h2 >
                    <p className="font-roboto font-normal text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400" > Explore the whole collection of open - source web components and elements built with the utility classes from Tailwind</p >
                </div >
                <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-1 lg:grid-cols-2" >
                    {teamMembers.map((member, index) => (
                        <div
                            key={index}
                            className="relative group marker:items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700"
                            style={styles[index] || {}}
                            onMouseMove={(e) => handleMouseMove(e, index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        >
                            <a href="#">
                                <img
                                    className="w-full  rounded-lg sm:rounded-none sm:rounded-l-lg"
                                    src={member.img}
                                    alt={`${member.name} Avatar`}
                                />
                            </a>
                            <div className="p-5">
                                <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    <a href="#">{member.name}</a>
                                </h3>
                                <span className="text-gray-900 dark:text-gray-400 font-roboto">{member.position}</span>
                                <p className="mt-3 mb-4 font-light text-gray-700 font-roboto ">{member.description}</p>
                                <ul className="flex space-x-4 sm:mt-0">
                                    <li>
                                        <a href="#" className="text-gray-500 hover:text-gray-900 ">
                                            <FaFacebook className="w-5 h-5" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-gray-500 hover:text-gray-900 ">
                                            <FaSquareXTwitter className="w-5 h-5" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="text-gray-500 hover:text-gray-900">
                                            <FaGithub className="w-5 h-5" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
                </div >
            </div >
        </section >
    )
}
