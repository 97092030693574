import SectionOne from './HomeSections/SectionOne'
import SectionTwo from './HomeSections/SectionTwo'
import SectionThree from './HomeSections/SectionThree'
import SectionFour from './HomeSections/SectionFour'
import SectionFive from './HomeSections/SectionFive'

export default function Home() {

    return (
        <>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
        </>
    )
}